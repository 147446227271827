import {Box, Button, Chip} from '@mui/material';
import React, {useState, useEffect} from 'react';
import WeatherDefinitionLine from 'widgets/weatherDefinition/WeatherDefinitionLine';
import {
    fetchRealLocations,
    fetchAlertConfigurations,
    fetchWeatherIcons,
    fetchConfigurationGroups, fetchWeatherDefinitions
} from "pages/alerts/api/FetchAlerts";
import {SearchInput} from "shared/ui/SearchInput";
import InfoPanel from "../../../../../shared/ui/InfoPanel";
import {Spacer} from "../../../../../shared/ui/Spacer";
import {SeveritySelector} from "../../../../../features/weatherDefinition/SeveritySelector";


export default function WeatherDefinitionStepScreen({onChange, selectedDefinitions, isEditingMode}) {
    const [definitions, setDefinitions] = useState([]);
    // const [openDefinition, setOpenDefinition] = React.useState(null);
    const [icons, setIcons] = useState([]);
    const [locations, setLocations] = useState([]);
    const [alertConfigs, setAlertConfigs] = useState([]);
    const [configGroups, setConfigGroups] = useState([]);
    const [searchFilter, setSearchFilter] = useState('');
    const [severityFilter, setSeverityFilter] = useState('All');


    useEffect(() => {
        fetchWeatherIcons().then((data) => {
            setIcons(data)
        });
        fetchWeatherDefinitions().then((data) => {
            const definitions = data.sort((a, b) => a.name.localeCompare(b.name));
            setDefinitions(definitions)
        });
        fetchRealLocations().then((data) => {
            setLocations(data.location)
        });
        fetchAlertConfigurations().then((data) => {
            setAlertConfigs(data)
        });
        fetchConfigurationGroups().then((data) => {
            setConfigGroups(data)
        });
    }, []);

    useEffect(() => {
        if (!definitions || !icons) return;
        const icons_obj = {};
        icons.forEach(icon => {
            icons_obj[icon.id] = icon;
        });
        const definitions_obj = {};
        definitions.forEach(definition => {
            definition.icon_url = icons_obj[definition.icon]
            definitions_obj[definition.id] = definition;
            definition.configurations = {};
        });
        const locations_obj = {};
        locations.forEach(location => {
            locations_obj[location.id] = location;
        });
        const configs_obj = {};
        alertConfigs.forEach(config => {
            configs_obj[config.id] = config;
        });
        configGroups.forEach(relation => {
            const config = configs_obj[relation[1]];
            if (!config) return;
            const definition = definitions_obj[config.weather_definition_id];
            const location = locations_obj[config.location_id];
            if (!definition || !location) return;
            if (!definition.configurations[relation[0]]) definition.configurations[relation[0]] = [];
            definition.configurations[relation[0]].push(location);
        });
    }, [definitions, icons, locations, alertConfigs, configGroups]);

    // const deleteDefinition = (definition) => {
    //     deleteWeatherDefinition(definition.id).then((data) => {
    //         if(!data.success) return;
    //         const new_definitions = [];
    //         definitions.map((def) => {if (def !== definition) new_definitions.push(def)});
    //         setDefinitions(new_definitions);
    //     });
    // }

    const onAction = (action, definition) => {
        if (action === 'select') onChange('select', definition);
        if (action === 'edit') onChange('edit', definition);
        if (action === 'open') onChange('open', definition);
    }

    const filterName = (name) => {
        if (searchFilter === '') return true;
        const normName = name.toLowerCase();
        const normFilter = searchFilter.toLowerCase();
        if (normName.indexOf(normFilter) >= 0) return true;
        return false;
    }

    const handleSeverityChange = (event) => {
        setSeverityFilter(event.target.value);
    };

    const handleUnchoseWeatherDefinition = (wd) => {
        onChange('select', wd)
    };

    return (
        <Box className={'WeatherDefinitionStepScreen column gap16'} style={{width: 'auto', height: "100%"}}>
            {!isEditingMode &&
                <InfoPanel title={"Multiple weather definitions"}
                           text={"Choosing multiple weather definitions creates separate alerts, one per definition, all applying to your locations. For specific settings (reports, notifications), create an alert with separate definitions and desired settings."}/>
            }
            <Box className={'row'} style={{padding: "0 2px"}}>
                <Box style={{display: "flex", gap: "8px"}}>
                    <SearchInput
                        style={{width: "304px"}}
                        placeholder='Search for weather definitions'
                        onChange={event => setSearchFilter(event.target.value)}
                    />
                    <SeveritySelector severity={severityFilter} onChange={handleSeverityChange}/>
                </Box>
                <Spacer/>
                <Button
                    data-cy={'new-definition-button'}
                    onClick={() => {
                        onChange('open_new', {})
                    }}
                >
                    Create custom definition
                </Button>
            </Box>
            <div className={'column gap16'} style={{width: 'auto', height: "100%"}}>
                <div style={{
                    backgroundColor: "var(--palette-grey-background)",
                    padding: "16px",
                    color: "var(--palette-grey-400)",
                    fontWeight: "500",
                    fontSize: "12px",
                    borderRadius: "12px"
                }}>
                    <span style={{display: "flex", gap: "8px", alignItems: "center", flexWrap: "wrap"}}>
                    {selectedDefinitions.length > 0 ? `SELECTED DEFINITIONS (${selectedDefinitions.length}):` : "NO SELECTED DEFINITIONS"}
                        {selectedDefinitions.map((wd) => (
                            <Chip onClick={() => handleUnchoseWeatherDefinition(wd)}
                                  onDelete={() => handleUnchoseWeatherDefinition(wd)} label={wd.name} variant={'grey'}/>
                        ))}
                    </span>
                </div>
                <div style={{height: "100%", overflowY: "auto", display:"flex", flexDirection:"column", gap:"8px"}}>
                    {definitions.map(wd => (filterName(wd.name) && (wd.severity === severityFilter || severityFilter === "All") &&
                        <WeatherDefinitionLine definition={wd} selectedDefinitions={selectedDefinitions} isAlertEditingMode={isEditingMode}
                                               onChange={onAction}/>
                    ))}
                </div>
            </div>
        </Box>
    )
} 
