import {Dialog, IconButton, Box, Button, Divider} from '@mui/material';
import CloseIcon from "shared/assets/icons/Close";
import React from "react";
import {ReactComponent  as ConfirmationRectangle1} from "shared/assets/svg/confirmationPopUp/ConfirmationRectangle1.svg";
import {ReactComponent  as ItemConfirmationRectangle} from "shared/assets/svg/confirmationPopUp/ItemConfirmationRectangle.svg";
import {ReactComponent  as ConfirmationPin1} from "shared/assets/svg/confirmationPopUp/ConfirmationPin.svg";
import {ReactComponent  as ItemConfirmationPin2} from "shared/assets/svg/confirmationPopUp/ItemConfirmationPin2.svg";
import {ReactComponent  as ItemConfirmationRectangle2} from "shared/assets/svg/confirmationPopUp/ItemConfirmationRectangle2.svg";
import {ReactComponent  as ItemConfirmationRectangle3} from "shared/assets/svg/confirmationPopUp/ItemConfirmationRectangle3.svg";
import {ReactComponent as Background} from 'shared/assets/svg/confirmationPopUp/BackgroundAconfirmation.svg'
import Urgency from "../../pages/settings/ui/actionItems/Urgency";

export default function ActionItemConfirmationModal({name, urgency, agreeFunc, goToSettingsFunc}) {

    const content = (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '504px',
            gap: '24px',
            padding: '12px 16px'
        }}>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center'}}>
                <Box
                    className='modal-header'
                    sx={{display: 'flex'}}
                >
                    Well done, your action item is created!
                </Box>
                <IconButton
                    className='modal__close-button'
                    onClick={agreeFunc}
                    sx={{marginLeft: 'auto', padding: '4px', width: '32px', height: '32px'}}
                >
                    <CloseIcon sx={{width: '16px', height: '16px'}}/>
                </IconButton>
            </Box>
            <Box style={{position: "relative", height: "80px", overflow: "hidden"}}>
                <Box style={{position: "absolute", left: '0px', top: '0px'}}>
                    <Background/>
                </Box>
                <Box style={{position: "absolute", left: '0px', top: '0px'}}>
                    <ConfirmationRectangle1/>
                </Box>
                <Box style={{position: "absolute", left: '-25px', top: '35px'}}>
                    <ItemConfirmationRectangle/>
                </Box>
                <Box sx={{position: 'absolute', left: '200px', top: '10px'}}>
                    <ConfirmationPin1/>
                </Box>
                <Box sx={{position: 'absolute', right: '135px', top: '5px'}}>
                    <ItemConfirmationPin2/>
                </Box>
                <Box sx={{position: 'absolute', right: '0px', top: '10px'}}>
                    <ItemConfirmationRectangle2/>
                </Box>
                <Box sx={{position: 'absolute', right: '0px', top: '52px'}}>
                    <ItemConfirmationRectangle3/>
                </Box>
            </Box>
            <Box style={{
                height: "48px",
                padding: "8px 16px",
                border: "1px solid var(--palette-grey-100)",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                <div style={{fontSize: "16px", fontWeight: "500", color: "var(--palette-grey-900)"}}>
                    {name}
                </div>
                <Urgency Urgency={urgency}/>
            </Box>
            <Box
                className='modal-delete-text'
                sx={{width: '100%', fontSize: '16px'}}
            >
                You can overview an action item on the action items page. To manage action items settings, go to Settings > Action items.
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: "12px"}}>
                <Divider/>
                <Box sx={{display: 'flex', gap: '12px'}}>
                    <Button
                        style={{fontWeight: '400', height: '40px', width: '100%'}}
                        onClick={agreeFunc}
                    >
                        Got it
                    </Button>
                    <Button
                        style={{fontWeight: '400', height: '40px', width: '100%'}}
                        variant={'outlined'}
                        color={'secondary'}
                        onClick={goToSettingsFunc}
                    >
                        Go to Settings
                    </Button>
                </Box>
            </Box>
        </Box>
    )
    return (
        <Dialog
            open={true}
            onClose={agreeFunc}
            sx={{'& .MuiPaper-root': {maxWidth: '700px'}}}
        >
            {content}
        </Dialog>
    )
}
