import {
    Lightnings,
    CurrentConditions,
    Nexrad,
    NhcTropicalTracks, BaronStormTracks,
} from 'features/textProducts';
import maplibregl from 'maplibre-gl';


/**
 * properties from features should contains field "displayPopupInfo"
 * as object for display, e.g.
 * displayPopupInfo: { name: "name"}
 * 
 * 
 */

export class TextProductHandler{

    constructor(map, signature){
        this.map = map;
        this.signature = signature;
        this.popupElement = document.createElement('div');
        this.popupElement.className = 'impmarker';
        this.popupElement.addEventListener('click',event=>{
            event.stopPropagation();
        });
        this.popupElement.addEventListener('wheel',event=>{
            event.stopPropagation();
        });
        this.popupElement.addEventListener('dblclick',event=>{
            event.stopPropagation();
        });
        this.popup = new maplibregl.Marker({
            element: this.popupElement,
            color: "#FF0000",
            anchor: 'top',
            draggable: true
        });
        this.enabledProducts = {};
        this._clickEventHandler = this._clickEventHandler.bind(this);
        this._popupOnMap = false;
        this._time = undefined;
        map.on('click', this._clickEventHandler);
    }

    _clickEventHandler(e, isRenew){
        this._lastClickEvent = e;

        let bbox = [
            [e.point.x - 5, e.point.y - 5],
            [e.point.x + 5, e.point.y + 5]
        ];

        //queryRenderedFeatures is possible returns duplicates, then
        // for text data we can display only unique "displayPopupInfo", because 
        // it stored as string.
        let defaultInfoFeatures = this.map.queryRenderedFeatures(bbox);
        let htmlContent = `<div class="impmarker-header"><div class="impmarker-header-label">Information</div><div class="impmarker-closebtn">×</div></div><div class="impmarker-body">`,
            contentArr = [];
        defaultInfoFeatures.forEach(f=>{
            if (f.properties && f.properties.displayPopupInfo){
                if (!contentArr.includes(f.properties.displayPopupInfo)){
                    contentArr.push(f.properties.displayPopupInfo);
                }
            }
        });
        contentArr.forEach((str,index)=>{
                const data = JSON.parse(str);
                for (let key in data){
                    
                    if (!data[key]){
                        htmlContent += `<div class="impmarker-simplebody-line"><span>${key}</span></div>`;
                    }else{
                        htmlContent += `<div class="impmarker-simplebody-line"><span class="impmarker-simplebody-line-type">${key}: </span> <span class="impmarker-simplebody-line-value">${data[key]}</span></div>`;
                    }
                }
                if (index !== contentArr.length-1){
                    htmlContent += '<hr/>';
                }
        });
        htmlContent+='</div>';

        if (contentArr.length){
            this.popupElement.innerHTML = htmlContent;
            const anchorPopup = (isRenew && this.popup._lngLat) ?this.popup._lngLat : e.lngLat;
            this.popup.remove();
            this.popup.setLngLat(anchorPopup).addTo(this.map);
            this._popupOnMap = true;
            this.popupElement.querySelector('.impmarker-closebtn').addEventListener('click', event=>{
                this.popup.remove();
                this._popupOnMap = false;
                event.stopPropagation();
            });
        }else{
            if (isRenew){
                this.popup.remove();
                this._popupOnMap = false;
            }
        }

        // SINGLE SITE RADAR
        const filteredSSR = defaultInfoFeatures.filter(f => (f.layer.id==='nexrad1'));
        if (filteredSSR.length===1 && (this.map.customSettingSSR!==filteredSSR[0].properties.station)){

            let old = map.querySourceFeatures('source-nexrad', {
                sourceLayer: 'nexrad1'
            }).find(f=>(f.properties.station===this.map.customSettingSSR));
            if (old) {
                this.map.setFeatureState(
                    {source: 'source-nexrad', id: old.id},
                    {selected: false}
                );
            }
            this.map.setFeatureState(
                {source: 'source-nexrad', id: filteredSSR[0].id},
                {selected: true}
            );
            this.map.customSettingSSR = filteredSSR[0].properties.station;
            this.map.fire('change_customSettingSSR');
        }

    }

    enableProduct(productName, productObjects){
        if ( this.enabledProducts[productName]){
            return;
        }
        const mappedProd = this._mapClasses[productName];
        if (!mappedProd || !mappedProd.constructorClass){
            return;
        }

        let product = productObjects.find(p => p.api_product_code === productName)
        let layerTextProduct = new mappedProd.constructorClass(Object.assign({
            textProductsHandler: this,
            map: this.map,
            signature: this.signature,
            productCode: productName,
            product: product
        }, mappedProd.options ? mappedProd.options : {}));
        layerTextProduct.enable();
        this._time && layerTextProduct.setTime && layerTextProduct.setTime(this._time);
        this.enabledProducts[productName] = layerTextProduct;
    }

    isEnabled(productName) {
         return !!this.enabledProducts[productName]
    }

    getProduct(productName) {
         return this.enabledProducts[productName]
    }

    disableProduct(productName){
        if (!this.enabledProducts[productName]){
            return;
        }
        this.enabledProducts[productName].disable();
        delete this.enabledProducts[productName];
    }

    renewPopup(){
        if (this._popupOnMap){
            this._clickEventHandler(this._lastClickEvent, true);
        }
    }

    setTime(time){
        this._time = time;
        for (let key in this.enabledProducts) {
            if (typeof(this.enabledProducts[key].setTime) === 'function') {
                this.enabledProducts[key].setTime(time);
            }
        }
    }

    _mapClasses = {
     /*   "metarsensors" : {
            constructorClass : sensors
        },
        "nws-bulletins":{
            constructorClass: alertsPolygons
        },
        "alert-all":{
            constructorClass: pinpoint
        },
        "spaghetti":{
            constructorClass : spaghettiLayer
        },
        "scits": {
            constructorClass: vectors,
            options: {currentProduct: 'nws'}
        },
        "hurricane-hunters":{
            constructorClass : huntersLayer
        },*/
        "lightning":{
            constructorClass: Lightnings
        },
        "metar": {
            constructorClass: CurrentConditions
        },
        "nhc_tropical_tracks": {
            constructorClass: NhcTropicalTracks
        },
        "nexrad":{
            constructorClass: Nexrad
        },
        'baron_storm_tracks': {
            constructorClass: BaronStormTracks,
            options: {
                type: BaronStormTracks.types.all
            }
        },
        'baron_storm_tracks_tornado_high_probability': {
            constructorClass: BaronStormTracks,
            options: {
                type: BaronStormTracks.types.tornadoHighProbability
            }
        },
        'baron_storm_tracks_tornado_low_probability': {
            constructorClass: BaronStormTracks,
            options: {
                type: BaronStormTracks.types.tornadoLowProbability
            }
        },
        'baron_storm_tracks_high_winds': {
            constructorClass: BaronStormTracks,
            options: {
                type: BaronStormTracks.types.highWinds
            }
        },
        'baron_storm_tracks_extreme_hail': {
            constructorClass: BaronStormTracks,
            options: {
                type: BaronStormTracks.types.extremeHail
            }
        },
        'baron_storm_tracks_hail': {
            constructorClass: BaronStormTracks,
            options: {
                type: BaronStormTracks.types.hail
            }
        },
        'baron_storm_tracks_storm': {
            constructorClass: BaronStormTracks,
            options: {
                type: BaronStormTracks.types.storm
            }
        },
        /*  "earthquakes":{
              constructorClass : earthquakesLayer
          }*/
    }
}