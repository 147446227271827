import {Dialog, IconButton, Box, Button} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import CloseIcon from 'shared/assets/icons/Close'
import React from 'react'
import TrashIcon from "../assets/icons/Trash";
import PauseIcon from "../assets/icons/PauseIcon";
import StartIcon from "../assets/icons/StartIcon";

export default function AgreeModal({width, wait, data}) {
    const {agreeFunc, title, message, agreeMsg, disAgreeMsg = 'Go back', mode = 'default'} = data

    const renderIcon = () => {
        switch (mode) {
            case 'deleting':
                return <TrashIcon />;
            case 'pausing':
                return <PauseIcon />;
            case 'restarting':
                return <StartIcon />;
            case 'default':
                return null;
            default:
                return null;
        }
    };

    const content = (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            padding: '12px 16px',
            width: '500px',
            gap: "16px"
        }}
        >
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center', height:"32px"}}>
                <Box
                    className='modal-header'
                    sx={{display: 'flex', fontWeight: '500'}}
                >
                    {title}
                </Box>
                <IconButton
                    onClick={() => {
                        agreeFunc(false)
                    }}
                    disabled={wait}
                    variant={'outlined'}
                >
                    <CloseIcon
                        size={'small'}
                    />
                </IconButton>
            </Box>

            <Box
                className='modal-delete-text'
                sx={{width: '100%'}}
            >
                {message}
            </Box>
            <Box
                className='modal-buttons'
                sx={{display: 'flex', gap: '12px', width:"fit-content"}}
            >
                <Button
                    style={{width: "fit-content", fontWeight:"500"}}
                    className='spacer'
                    variant={'outlined'}
                    color={'secondary'}
                    disabled={wait}
                    onClick={() => {
                        agreeFunc(false)
                    }}
                >
                    {disAgreeMsg}
                </Button>
                <Button
                    style={{width: "fit-content", fontWeight:"500"}}
                    className='spacer'
                    variant={'contained'}
                    color={'error'}
                    onClick={() => {
                        agreeFunc(true)
                    }}
                    disabled={wait}
                >
                    <span style={{display:"flex", alignItems:"center", gap:"4px"}}>{renderIcon()} {agreeMsg}</span>
                </Button>
                {wait && <CircularProgress sx={{marginLeft: '10px'}}/>}
            </Box>
        </Box>
    )
    return (
        <Dialog
            open={true}
            onClose={() => {
                agreeFunc(false)
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '700px'}}}
        >
            {content}
        </Dialog>
    )
}
