import {BaronStormTracks} from 'features/textProducts'


const AdditionalProducts = [
    {
        "id": -999,
        "name": "Radar Site Selector",
        "product_id": "nexrad",
        "product_group": "Single Site Radar",
        "icon": "/images/icons/radar.svg",
        "api_product_code": "nexrad"
    },
    {   
        "id": -1000,
        "name": "Contoured Super-Res Reflectivity",
        "product_id": "{SSR}-0x0466-1",
        "product_group": "Single Site Radar",
        "icon": "/images/icons/radar.svg",
        "api_product_code": "{SSR}-0x0466-1"
    },
    {   
        "id": -1001,
        "name": "Non-Contoured Super-Res Reflectivity",
        "product_id": "{SSR}-0x0466-1-N",
        "product_group": "Single Site Radar",
        "icon": "/images/icons/radar.svg",
        "api_product_code": "{SSR}-0x0466-1-N"
    },
    {   
        "id": -1002,
        "name": "Velocity",
        "product_id": "{SSR}-0x0422-1",
        "product_group": "Single Site Radar",
        "icon": "/images/icons/radar.svg",
        "api_product_code": "{SSR}-0x0422-1"
    },
    {   
        "id": -1003,
        "name": "Severe Threats",
        "product_id": "{SSR}-0x0265-0",
        "product_group": "Single Site Radar",
        "icon": "/images/icons/radar.svg",
        "api_product_code": "{SSR}-0x0265-0"
    },
    {   
        "id": -1004,
        "name": "Contoured Damaging Wind",
        "product_id": "{SSR}-0x0575-1",
        "product_group": "Single Site Radar",
        "icon": "/images/icons/radar.svg",
        "api_product_code": "{SSR}-0x0575-1"
    },
    {   
        "id": -1005,
        "name": "Non-Contoured Damaging Wind",
        "product_id": "{SSR}-0x0575-1-N",
        "product_group": "Single Site Radar",
        "icon": "/images/icons/radar.svg",
        "api_product_code": "{SSR}-0x0575-1-N"
    },
    ...Object.values(BaronStormTracks.products),
]

export default AdditionalProducts