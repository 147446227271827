import React, {useEffect, useState} from 'react';
import {DatePeriodPicker} from "../../../shared/ui/datePickers/DatePeriodPicker";
import {TimeLineSidebar, TimeLineMenuMini} from '../TimeLineSidebar';
import NewWeatherDefinitionPage from '../../../widgets/weatherDefinition/NewWeatherDefinitionPage';
import {WeatherDefinitionDetails} from '../../../entities/weatherDefinition/ui/WeatherDefinitionDetails';
import {TimeLineChartsMemoized} from '../timelineCharts/TimelineCharts';
import {Box, Button, Typography} from '@mui/material';
import dayjs from 'dayjs';
import {useSearchParams} from 'react-router-dom';

import WeatherDefinitionDrawer from '../../../features/weatherDefinition/WeatherDefinitionDrawer';
import TimeLineElipsis from '../TimeLineElipsis';
import {
    createTimelineView, deleteTimelineView,
    deleteWeatherDefinition, editTimelineView,
    fetchRealAlertByAid,
    fetchRealAlerts,
    fetchRealLocations, fetchTimelineViews,
    fetchWeatherDefinitions,
    fetchWeatherIcons
} from "../../alerts/api/FetchAlerts";
import {prepApiAlert} from "pages/alerts/ui/AlertsPage";
import {EditLocationScreen} from "widgets/location";
import './TimeLine.css';
import generalCautionIcon from "../../../shared/assets/weatherIcons/general_caution.svg";
import ManageAlertConfigurationPanel from 'widgets/alertConfig/ui/manageAlertConfigurationPanel/ManageAlertConfigurationPanel';
import {Spacer} from "shared/ui/Spacer";
import { AlertConfigsFilter } from 'widgets/alertConfig'
import AgreeModal from "shared/ui/AgreeModal";
import SaveViewModal from "../../../shared/ui/SaveViewModal";
import PlusIcon from "../../../shared/assets/icons/Plus";


const MIN_CHART_WIDTH = 900;
const API_ALERTS_TIMEDELTA = 365 / 2 * 24 * 3600;  // half year seconds

function TimeLine({ collapseSidebar, onCollapse }) {

    const [isWeatherDefinitionDrawer, setIsWeatherDifinitionDrawer] = useState(false);

    const [period, setPeriod] = React.useState('Week');
    const timelinePos = React.useRef(Date.now());
    const navigatorExtent = React.useRef(null);
    const apiAlertsTimePos = React.useRef();
    const chartRef = React.useRef();
    const [timelineRange, setTimelineRange] = useState({});
    const [updated, setUpdated] = React.useState(0);  // invert this value to force rerender
    const apiWeatherIconsRef = React.useRef(null);
    const apiAlertsRef = React.useRef({});
    const apiLocationsRef = React.useRef(null);
    const apiWeatherDefinitionsRef = React.useRef(null);
    const apiUpdatedRef = React.useRef(false);
    const alertsRef = React.useRef(null);
    const filteredAlertsRef = React.useRef(null);
    const locationGroupsRef = React.useRef();
    const [selectedLocation, setSelectedLocation] = React.useState(null);
    const [severityFilter, setSeverityFilter] = React.useState('all');
    const [selectedDefinitions, setSelectedDefinitions] = React.useState({});
    const [openAddLocations, setOpenAddLocations] = useState(false);
    const [openDefinition, setOpenDefinition] = useState(null);
    const [copyDefinition, setCopyDefinition] = useState(null);
    const [editAlertConfig, setEditAlertConfig] = useState(null);
    const openAlert = React.useRef(null);

    const timezone = dayjs.tz.guess();

    const [query] = useSearchParams();

    const [selectedConfigs, setSelectedConfigs] = useState({configs: null});

    const [views, setViews] = useState([]);

    const [saveViewModal, setSaveViewModal] = useState(false);
    const [editViewModalID, setEditViewModalID] = useState(null);
    const [deleteViewModalID, setDeleteViewModalID] = useState(null);
    const checkBoxesInSaveModal = [
        {key: 'scale', text: 'Period'},
        {key: 'position', text: 'Timeline date'},
        {key: 'configs', text: 'Alerts filter'},
        {key: 'location', text: 'Selected location'},
    ];

    const newView = {
        id: '',
        title: '',
        includes: {scale: true, position: true, location: true, configs: true},
        data: {
            configs: selectedConfigs.configs?Object.values(selectedConfigs.configs).filter(c => c.selected).map(c => c.group.groupId):null,
            scale: period,
            position: timelinePos.current,
            location: selectedLocation?.id,
        }
    };

    const loadMenuItemsObj = {};
    for (let idx in views) {
        loadMenuItemsObj[views[idx].id] = views[idx];
    }

    const editedViews = Object.fromEntries( //Old views with values from newView
        Object.keys(loadMenuItemsObj).map(id => [
            id,
            {
                ...newView,
                id: loadMenuItemsObj[id].id,
                title: loadMenuItemsObj[id].title,
            }
        ])
    );

    useEffect(() => {
        fetchTimelineViews().then(newViews => {
            newViews.forEach(v => { Object.assign(v, v.data_json); delete v.data_json});
            setViews(newViews);
        });
    }, []);

    const onAlertConfigsFilterAction = (action, obj) => {
        switch (action) {
            case 'edit_alert':
                setEditAlertConfig({config: obj});
                break;
            default:
                console.error('no action handler for action ', action)
                break;
        }
    }

    const onViewAction = (action, id) => {
        switch (action) {
            case 'save_view':
                setSaveViewModal(true);
                break;
            case 'edit_view':
                setEditViewModalID(id);
                break;
            case 'delete_view':
                setDeleteViewModalID(id);
                break;
            default:
                console.error('no action handler for action ', action)
                break;
        }
    }

    useEffect(() => {
        const selected = {...selectedDefinitions};
        if (apiWeatherDefinitionsRef.current === null) return;
        const definitions = apiWeatherDefinitionsRef.current;
        definitions.forEach((def) => {
            if (selected[def.id] === undefined) selected[def.id] = true;
        })
        setSelectedDefinitions(selected);
    }, [updated]);
    const calculateChartWidth = () => {
        return Math.max(window.innerWidth - (!collapseSidebar ? 335 : 96), MIN_CHART_WIDTH);
    };

    const [chartWidth, setChartWidth] = useState(calculateChartWidth());

    const handleWindowResize = () => {
        setChartWidth(calculateChartWidth());
    }

    window.addEventListener('resize', handleWindowResize)

    useEffect(() => {
        if (!navigatorExtent.current) return;
        const navigatorPos = (navigatorExtent.current[0] + navigatorExtent.current[1]) * 0.5;
        const navigatorPosDelta = Math.abs(navigatorPos - apiAlertsTimePos.current);
        if (navigatorPosDelta < 90 * 24 * 3600 * 1000) return;
        apiAlertsTimePos.current = navigatorPos;
        apiAlertsRef.current.ts_after = 0;
        periodicalUpdate();
    }, [timelineRange]);

    const updateOnChanges = (stateRef) => {
        return (new_state) => {
            if (!new_state) {
                return;
            }
            const state = stateRef.current;
            let changed = false;
            if (new_state.location) new_state = new_state.location;
            if (!state) {  // first update
                stateRef.current = new_state;
                apiUpdatedRef.current = true;
                setUpdated(new_state);
                return;
            }
            for (let idx in new_state) {
                if (!state[idx] || state[idx].id !== new_state[idx].id) { changed = true; break }
                if (state[idx].changed) {
                    if (state[idx].changed !== new_state[idx].changed) { changed = true; break }
                    continue;
                }
                for (let key in new_state[idx]) {
                    if (state[idx][key] !== new_state[idx][key]) { changed = true; break }
                }
                if (changed) break;
            }
            if (!changed) return;
            stateRef.current = new_state;
            apiUpdatedRef.current = true;
            setUpdated(new_state);
        };
    };

    const updateApiAlerts = (data) => {
        const ts_after_cur = apiAlertsRef.current.ts_after;
        if (!data || (!data.length && ts_after_cur)) return;
        let ts_after = (!data.length)?0:[...data.map(a=>a.update_time)].sort()[data.length - 1];
        console.log(ts_after);
        const aids = {};
        let changed = false;
        if (!apiAlertsRef.current.data) {
            apiAlertsRef.current.data = data;
            changed = true;
        }
        else if (!ts_after_cur && ts_after) {
            apiAlertsRef.current.data = data;
            changed = true;
        }
        else {
            for (let idx in apiAlertsRef.current.data) {
                const a = apiAlertsRef.current.data[idx];
                aids[a.aid] = idx;
            }
            for (let idx in data) {
                const a = data[idx];
                if (aids[a.aid]) {
                    if (apiAlertsRef.current.data[aids[a.aid]].update_time !== a.update_time) {
                        apiAlertsRef.current.data[aids[a.aid]] = a;  // alert exists already, updating
                        changed = true;
                    }
                }
                else { apiAlertsRef.current.data.push(a); changed = true; }
            }
        }
        const min_ts_after = dayjs.utc().subtract(15, 'minute').toISOString().split('.')[0] + 'Z';
        if (!ts_after || ts_after < min_ts_after) ts_after = min_ts_after;
        apiAlertsRef.current.ts_after = ts_after;
        if (!changed) return;
        apiUpdatedRef.current = true;
        setUpdated(data);
    };

    const periodicalUpdate = () => {
        fetchRealLocations().then(updateOnChanges(apiLocationsRef));
        fetchWeatherDefinitions().then(updateOnChanges(apiWeatherDefinitionsRef));
        fetchWeatherIcons().then(updateOnChanges(apiWeatherIconsRef));
        const ts_min = Math.round(apiAlertsTimePos.current / 1000 - API_ALERTS_TIMEDELTA);
        const ts_max = Math.round(apiAlertsTimePos.current / 1000 + API_ALERTS_TIMEDELTA);
        if (!apiAlertsRef.current.ts_after) fetchRealAlerts(0, ts_min, ts_max).then(updateApiAlerts);
        else fetchRealAlerts(dayjs(apiAlertsRef.current.ts_after).unix(), ts_min, ts_max).then(updateApiAlerts);  // fetch new alerts only
        console.log('Timeline alerts update started')
    }


    const onOpenAlertApi = (data) => {
        if (data && !data.error) {
            const aid = openAlert.current.id;
            const alert_idx = (aid.indexOf('_') > 0)?parseInt(aid.split('_')[1]):0;
            const alert = prepApiAlert(data)[alert_idx];
            if (alert) {
                timelinePos.current = alert.start_date;
            }
        }
        apiAlertsTimePos.current = timelinePos.current;
        periodicalUpdate();
    }

    useEffect(() => {
        let updater = null;
        updater = setInterval(periodicalUpdate, 60000);
        console.log('Timeline alerts updater started')
        const alertArg = query.get('alert_id');
        if (alertArg) {
            openAlert.current = {id: alertArg};
            fetchRealAlertByAid(alertArg).then(onOpenAlertApi);
        }
        else {
            apiAlertsTimePos.current = timelinePos.current;
            periodicalUpdate();
        }
        return () => {
            console.log('Timeline alerts updater stopped')
            clearInterval(updater);
        }
    }, []);

    const prepAlerts = () => {
        const apiWeatherIcons = apiWeatherIconsRef.current;
        const apiAlerts = apiAlertsRef.current.data;
        const apiLocations = apiLocationsRef.current;
        const apiWeatherDefinitions = apiWeatherDefinitionsRef.current;
        if(!apiAlerts || !apiWeatherIcons || !apiLocations || !apiWeatherDefinitions) return;  // the inital api data is not received yet
        if (!apiUpdatedRef.current) return;
        apiUpdatedRef.current = false;
        const icons = {};
        for (let idx in apiWeatherIcons) {
            icons[apiWeatherIcons[idx].id] = apiWeatherIcons[idx];
        }
        const wds = {};
        for (let idx in apiWeatherDefinitions) {
            apiWeatherDefinitions[idx].icon_obj = icons[apiWeatherDefinitions[idx].icon];
            if (!apiWeatherDefinitions[idx].icon_obj) apiWeatherDefinitions[idx].icon_obj = {url: generalCautionIcon}
            wds[apiWeatherDefinitions[idx].id] = apiWeatherDefinitions[idx];
        }
        const locs = {};
        const groups = {};
        for (let idx in apiLocations) {
            const location = apiLocations[idx]
            locs[location.id] = location;
            // aggregating loctions into groups with alert counts
            if (!groups[location.location_group])
                groups[location.location_group] = {};
            groups[location.location_group][location.id] = {
                id: location.id,
                label: location.label,
                forecast_max: dayjs().valueOf(),
                Critical: 0,
                Severe: 0,
                Moderate: 0,
                type: location.geometry_type.toLowerCase(),
            };
        }

        const validAlerts = [];
        const now = dayjs().valueOf();
        for (let idx in apiAlerts) {
            const apiAlert = apiAlerts[idx];
            apiAlert.location = locs[apiAlert.loc_id];
            apiAlert.weather_definition = wds[apiAlert.wdef_id];
            if (!apiAlert.location || !apiAlert.weather_definition) continue;
            if (apiAlert.alert_type === 'usereventsalertclear') continue;
            if (apiAlert.issue_time === apiAlert.expiration_time) continue;
            if (apiAlert.alert_type === 'usereventsalert' && !apiAlert.triggered_rules) continue;
            const last_alerts_length = validAlerts.length;
            validAlerts.push(...prepApiAlert(apiAlert))

            const alert_stats = groups[apiAlert.location.location_group][apiAlert.loc_id];
            for (let new_idx = last_alerts_length; new_idx < validAlerts.length; new_idx++) {
                const new_alert = validAlerts[new_idx];
                if (new_alert.end_date > now || new_alert.triggered) alert_stats[new_alert.severity] += 1
                if (alert_stats.forecast_max < new_alert.end_date) alert_stats.forecast_max = new_alert.end_date;
                if (openAlert.current && openAlert.current.id === new_alert.alert_id) {
                    timelinePos.current = new_alert.start_date;
                    openAlert.current.location = alert_stats;
                }
            }
        }
        console.log('Timeline alerts update done');
        alertsRef.current = validAlerts;

        const groupList = [];
        let ungrouped = [];
        for (let key in groups) {
            const locs = [];
            for (let label in groups[key]) locs.push(groups[key][label]);
            if (!key) {
                ungrouped = locs;
                continue
            }
            groupList.push({
                group: key,
                locations: locs,
            });
        }
        groupList.sort((a, b) => {return (a.group < b.group)?-1:1})
        if (ungrouped.length)
            groupList.push({group: 'Ungrouped locations', locations: ungrouped})
        locationGroupsRef.current = groupList;

        setUpdated(!updated);
    };
    prepAlerts();
    let alerts = alertsRef.current;
    if (alerts === null) return (<></>)


    if (openAlert.current && openAlert.current.location && !selectedLocation) setSelectedLocation(openAlert.current.location);
    if (!openAlert.current && !selectedLocation && locationGroupsRef.current.length) setSelectedLocation(locationGroupsRef.current[0].locations[0]);

    function getMinDate() {
        let minDates = alerts.map((a) => {
            return new Date(a.start_date).getTime();
        })
        return Math.min(...minDates);
    }

    function getMaxDate() {
        let maxDates = alerts.map((a) => {
            return new Date(a.end_date).getTime();
        })
        return Math.max(...maxDates);
    }

    const saveSelectedDefinitions = (selected) => {
        setSelectedDefinitions(selected);
        setIsWeatherDifinitionDrawer(false);
    }

    const selectedDefinitionsCount = () => {
        let count = 0
        for (let key in selectedDefinitions)
            if (selectedDefinitions[key]) count += 1;
        return count;
    }

    const doDefinitionAction = (action, definition) => {
        if (action === 'open') setOpenDefinition(definition);
        else if (action === 'copy') setCopyDefinition(definition);
        else if (action === 'edit_configuration') { setEditAlertConfig({definition: openDefinition, config: definition}); setOpenDefinition(false);}
        else if (action === 'delete') {deleteWeatherDefinition(definition.id).then(() => {setOpenDefinition(false); periodicalUpdate()})}
    }

    const handleSaveNewView = (view) =>{
        setSaveViewModal(false);
        setEditViewModalID(null);

        if (!view) return;

        const apiView = {
            name: view.title,
            order: view.order,
            data_json: view,
        };
        delete view.name;
        delete view.order;

        if (!view.id) {
            delete view.id;
            createTimelineView(apiView).then(view => {
                if (view.error) return;
                Object.assign(view, view.data_json);
                delete view.data_json;
                setViews([...views, view])
            });
            return
        }
        apiView.id = view.id;
        delete view.id;
        editTimelineView(apiView).then(view => {
            if (view.error) return;
            Object.assign(view, view.data_json);
            delete view.data_json;
            views.forEach((v, idx) => {if(v.id === view.id) views[idx] = view});
            setViews([...views])
        });
    }

    const handleDeleteView = (agree, id) =>{
        setDeleteViewModalID(null);

        const view = loadMenuItemsObj[id];

        if (!view) return;
        if (!loadMenuItemsObj[view.id]) return;
        if(agree){
            deleteTimelineView(view.id);
            const newViews = views.filter(v => v.id !== view.id);
            setViews(newViews);
        }
    }

    return (
        <Box id="timeline-panel" className={'row fullWidth'} sx={{overflow: 'hidden'}}>
            {!openAddLocations && !openDefinition && !copyDefinition && !editAlertConfig &&
                <Box className={'row gap0 fullHeight fullWidth'} sx={{alignItems: 'start'}}>
                    {!collapseSidebar &&
                        <TimeLineSidebar
                            minDate={getMinDate()}
                            maxDate={getMaxDate()}
                            groupList={locationGroupsRef.current}
                            selectedLocation={selectedLocation}
                            setSelectedLocation={setSelectedLocation}
                            severityFilter={severityFilter}
                            setSeverityFilter={setSeverityFilter}
                            addLocations={()=>{setOpenAddLocations(true)}}
                            timezone={timezone}
                            navigatorExtentRef={navigatorExtent}
                            timelineRange={timelineRange}
                            chartRef={chartRef}
                        />
                    }
                    {collapseSidebar &&
                    <Box sx={{ width: '96px', display: 'flex', justifyContent: 'center' }}>
                        <TimeLineMenuMini
                            onCollapse={()=>onCollapse(false)}
                            groupList={locationGroupsRef.current}
                            selectedLocation={selectedLocation}
                            setSelectedLocation={setSelectedLocation}
                            addLocations={()=>{setOpenAddLocations(true)}}
                            timezone={timezone}
                            navigatorExtentRef={navigatorExtent}
                        />
                    </Box>}


                    <Box className={'container'}>
                        <Box className={'toolbar'}>
                            <h3>
                                {selectedLocation?.label}
                            </h3>
                            <Spacer/>
                            <TimeLineElipsis
                                chartRef={chartRef}
                                timelinePos={timelinePos}
                                period={period}
                                selectedConfigs={selectedConfigs}
                                locations={(() => {
                                    const locs = {};
                                    if (!locationGroupsRef.current) return locs;
                                    locationGroupsRef.current.forEach(group => {group.locations.forEach(loc => {locs[loc.id] = loc})});
                                    return locs;
                                })()}
                                selectedLocation={selectedLocation}
                                setUpdated={(newPeriod, newTimelinePos, newSelectedConfigs, newSelectedLocation) => {
                                    setPeriod(newPeriod);
                                    timelinePos.current = newTimelinePos;
                                    if (newSelectedConfigs.configs)
                                        setSelectedConfigs(newSelectedConfigs);
                                    if (newSelectedLocation)
                                        setSelectedLocation(newSelectedLocation);
                                    navigatorExtent.current = null;
                                    setUpdated(!updated);
                                }}
                                views={views}
                                onViewAction={onViewAction}
                                setDeleteViewModalID={setDeleteViewModalID}
                                setEditViewModalID={setEditViewModalID}
                                setSaveViewModal={setSaveViewModal}
                            />
                            <DatePeriodPicker
                                defaultPeriod={period}
                                dateRef={timelinePos}
                                onPeriodChange={value => {
                                    setPeriod(value);
                                    navigatorExtent.current = null;
                                }}
                                onChange={value => {
                                    if (value) {
                                        const pos = value["$d"].getTime();
                                        const dt = dayjs(pos).tz(timezone);
                                        let min, max;
                                        if (period === 'Day') {
                                            min = dt.startOf('day').valueOf();
                                            max = dt.endOf('day').valueOf();
                                        }
                                        else if (period === 'Week') {
                                            min = dt.startOf('week').valueOf();
                                            max = dt.endOf('week').valueOf();
                                        }
                                        else {
                                            min = dt.startOf('month').valueOf();
                                            max = dt.startOf('month').add(31, 'day').valueOf();
                                        }
                                        timelinePos.current = (min + max) / 2;
                                        navigatorExtent.current = null;
                                        setUpdated(!updated);
                                    }
                                }}
                            />
                            <AlertConfigsFilter
                                anchor={'right'}
                                selectedConfigs={selectedConfigs}
                                setSelectedConfigs={(c) => {
                                    const notSelectedConfigs = Object.values(c.configs).filter(config => !config.selected)
                                    const filteredAlerts = alerts.filter(alert => {
                                        return !notSelectedConfigs.some(config => {
                                            return config.group.definition_id === alert.api_alert.weather_definition.id
                                        })
                                    })
                                    setUpdated(() => {
                                        filteredAlertsRef.current = filteredAlerts
                                        new Date().getTime()
                                    })
                                    setSelectedConfigs(c)
                                }}
                                onChange={onAlertConfigsFilterAction}
                                openButtonText='Alerts'
                            />
                            <Button
                                data-cy={'new-alert-button'}
                                href='/alerts/newalert'
                                startIcon={<PlusIcon/>}
                            >
                                New alert
                            </Button>
                        </Box>
                        <TimeLineChartsMemoized
                            alerts={filteredAlertsRef.current}
                            period={period}
                            timelinePos={timelinePos}
                            navigatorExtent={navigatorExtent}
                            timezone={timezone}
                            chartRef={chartRef}
                            selectedLocation={selectedLocation}
                            severityFilter={severityFilter}
                            definitionFilter={selectedDefinitions}
                            chartWidth={chartWidth}
                            openDefinition={setOpenDefinition}
                            openAlertRef={openAlert}
                            setTimelineRange={setTimelineRange}
                        />
                    </Box>


                    <WeatherDefinitionDrawer open={isWeatherDefinitionDrawer}
                                             setIsWeatherDifinitionDrawer={setIsWeatherDifinitionDrawer}
                        anchor='right' onCancel={() => setIsWeatherDifinitionDrawer(false)}
                        onApply={saveSelectedDefinitions} savedSelectedDefinitions={selectedDefinitions} doAction={doDefinitionAction}/>

                </Box>
                }
                {openAddLocations &&
                    <EditLocationScreen sx={{width:'100%'}} closeOnSave={false} onClose={() => {periodicalUpdate(); setOpenAddLocations(false)}} />
                }
                {openDefinition &&
                    <WeatherDefinitionDetails definition_id={openDefinition.id} onChange={() => {setOpenDefinition(null)}} doAction={doDefinitionAction}/>
                }
                {copyDefinition &&
                    <NewWeatherDefinitionPage defaultValue={copyDefinition} onClose={() => setCopyDefinition(false)} />
                }
                {editAlertConfig &&
                    <ManageAlertConfigurationPanel args={editAlertConfig.config} onClose={() => {setOpenDefinition(editAlertConfig.definition); setEditAlertConfig(false)}} />
                }
                {saveViewModal &&
                    <SaveViewModal headerText='Save current view' saveText='Save view' saveFunc={handleSaveNewView} view={newView} checkBoxes={checkBoxesInSaveModal}/>
                }
                {editViewModalID &&
                    <SaveViewModal headerText='Edit view' saveText='Save changes' saveFunc={handleSaveNewView} view={editedViews[editViewModalID]} checkBoxes={checkBoxesInSaveModal}/>
                }
                {deleteViewModalID &&
                    <AgreeModal
                        data={{
                            message: <Box className='column' sx={{alignContent: 'stretch', overflow: "hidden", "&.MuiBox-root": {width:"100%"}}}>
                                <Typography sx={{fontSize: "18px"}}>{`Are you sure you want to delete "${loadMenuItemsObj[deleteViewModalID].title}" view?`}</Typography>
                            </Box>,
                            title: "Delete view",
                            agreeMsg: "Delete",
                            mode: "deleting",
                            agreeFunc: (agree) => handleDeleteView(agree, deleteViewModalID)
                        }}
                    />
                }

        </Box>
    );
}

export default TimeLine;