import {
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Chip, IconButton, Radio
} from '@mui/material';
import ArrowDownSmallIcon from "shared/assets/icons/ArrowDownSmall";
import React from 'react';
import {DefinitionRenderLarge} from './definitionRender/DefinitionRenderLarge';
import CopyIcon from "../../shared/assets/icons/Copy";
import LinkSquareIcon from "../../shared/assets/icons/LinkSquare";
import theme from "../../app/Theme";
import {CheckBox} from "../../shared/ui/CheckBox";


export default function WeatherDefinitionLine({definition, selectedDefinitions, isAlertEditingMode, onChange}){

    const handleDuplicate = (event) => {
        const dup_definition = JSON.parse(JSON.stringify(definition));
        dup_definition.id = -1;
        dup_definition.name = dup_definition.name + ' Copy';
        onChange('edit', dup_definition);
    }

    definition.groups = definition.rules;

    return(
        <Accordion data-cy={'weather-definition-line'} disableGutters elevation={0} square sx={{padding: 0, '&:before': {display: 'none'}, border: "1px solid var(--palette-grey-100)"}}>
                <AccordionSummary
                    expandIcon={
                        <IconButton
                            size='tiny'
                            variant='outlined'
                        >
                            <ArrowDownSmallIcon size={'small'}/>
                        </IconButton>}
                    aria-controls="panel1a-content"
                    sx={{ width: 'auto', display: 'flex', gap:"8px", alignItems: 'center', minHeight: '40px', height: '40px'}}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, gap:"8px" }}>
                        {isAlertEditingMode ?
                            <Radio style={{padding:"0"}} checked={selectedDefinitions.some(item => item.id === definition.id)} onChange={()=>{onChange('select', definition)}} onClick={(ev)=>{ev.stopPropagation()}}/> :
                            <CheckBox checked={selectedDefinitions.some(item => item.id === definition.id)} onChange={()=>{onChange('select', definition)}} onClick={(ev)=>{ev.stopPropagation()}}/>
                        }
                        <Typography sx={{display: 'flex', flexGrow: 1}}>{definition.name}</Typography>
                        <Box sx={{ maxWidth: '100px', marginLeft: 'auto', lineHeight: '16px'}}>
                            <Chip
                                size={'small'}
                                label={definition.severity}
                                variant={theme.palette.weather.variant[definition.severity]}
                            />
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails
                    className={'column fullWidth'}
                    sx={{
                        borderBottomRightRadius: '8px',
                        borderBottomLeftRadius: '8px',
                        backgroundColor: theme.palette.grey.background,
                    }}
                >
                    {definition.rules && <DefinitionRenderLarge definition={definition} />}
                    {!definition.rules && <Box>This is a fixed weather definition</Box>}
                    <Box className={'row gap8'}>
                        {definition.rules &&
                            <Button
                                startIcon={<CopyIcon size={'small'}/>}
                                size={'small'}
                                onClick={handleDuplicate}
                            >
                                Duplicate
                            </Button>}
                        <Button
                            startIcon={<LinkSquareIcon size={'small'}/>}
                            size={'small'}
                            variant={'outlined'}
                            onClick={() => {
                                onChange('open', definition)
                            }}
                        >
                            View Details
                        </Button>
                    </Box>
                </AccordionDetails>
        </Accordion>
    )
}